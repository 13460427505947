export default [
    {
        path: "/create-ad-form",
        name: "CreateAdForm",
        component: () => import("../pages/CreateAdForm.vue"),
    },
    {
        path: "/ad-details",
        name: "Ad Details",
        component: () => import("../pages/AdDetails.vue")
    }
]