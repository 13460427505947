import ApiRequest from "../services/ApiRequest";
import { LOCAL_MALL_BASE_API } from "../utils/constants";
import {GET, POST} from "../utils/HttpStatusCodeConst"

export default {
    getLocalMallShopsList(){
        return ApiRequest(LOCAL_MALL_BASE_API,`/shopService/get-seller-shop-list`, GET);
    },
    getLocalMallProductsOfShop({commit}, payload){
        commit("localMallProductsOfShop", payload)
        return ApiRequest(LOCAL_MALL_BASE_API, '/catalogueService/get-seller-product', POST,
        {
            "pageIndex": payload.page,
            "sellerShopId": payload.id,
            "status":"ACTIVE"
        })
    },
    // getAdDetails({commit}, id) {

    //     return ApiRequest(BASE_API_URL, `rest/campaign/details/${id}`)
    // }
}