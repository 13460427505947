import Vue from "vue";
import VueRouter from "vue-router";
import transaction from "@/router/transaction";
import adsRoutes from "./adsRoutes";
import termsAndConditions from "./termsAndConditions";
Vue.use(VueRouter);

const routes = [
  ...transaction,
  ...adsRoutes,
  ...termsAndConditions
];

const router = new VueRouter({
  routes,
});

export default router;
