export default [
  {
    path: "/",
    name: "Splash",
    component: () => import("../pages/Splash")
  },
  {
    path: "/dashboard",
    name: "MainLayout",
    component: () => import("../layout/main/MainLayout"),
    redirect: "/dashboard/my-ads",

    children: [
      {
        path: "my-ads",
        name: "AdsListing",
        component: () => import("../pages/AdsListing"),
      },
      {
        path: "wallet",
        name: "WalletHistory",
        component: () => import("../pages/WalletHistory"),
      },
    ],
  },
];
